import _ from "lodash";
import { i18n, dayjs } from "../../config";
import { regional_settings } from "../../constants";
import { BarChart } from "../Chart";
import { DualLabelSwitch } from '@gisce/oficina-virtual-components';
import { useCallback, useEffect, useState } from "react";
import ChartSkeleton from "../Chart/ChartSkeleton";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InvoicesChart = ({ invoices }) => {
  const { t } = useTranslation();
  const loaded = useSelector(state => state.invoices.loaded);

  const [chartData, setChartData] = useState({});
  const [toggleLeft, setToggleLeft] = useState(false);

  useEffect(() => {
    if( loaded ) {
      setChartData(chartInvoices(invoices));
    } else {
      setChartData({});
    }
  }, [loaded]);

  const chartInvoices = useCallback(invoices => {
    let components = {};
    const number_of_months = 12;

    const current_year = dayjs().year()
    let init_year = current_year;
    //find the earliest year
    invoices.forEach((invoice) => {
      let invoice_year = dayjs(invoice.end_date).year();

      if (invoice_year < init_year) {
        init_year = invoice_year;
      }
    });

    let energy_per_year = {};
    let amount_per_year = {};
    for (let year = init_year; year <= current_year; year++) {
      energy_per_year[year] = [];
      amount_per_year[year] = [];

      // Assign the 12 months
      for (let i = 0; i < number_of_months; i++) {
        energy_per_year[year].push({ name: i, total: 0 });
        amount_per_year[year].push({ name: i, total: 0 });
      }
    }

    invoices.forEach((invoice) => {
      const end_date = dayjs(invoice.end_date);

      // Extract the date
      const month = end_date.month();
      const year = end_date.year();
      const year_lite = year - 2000;

      const sign = invoice.rectificative_type === "A" ? -1 : 1;

      const amount = parseFloat(invoice.amount_total);
      const energy = parseFloat(invoice.energy_consumed) * sign;
      const contract = invoice.contract.name;

      // Ensure initialize correctly components with all CUPS
      if (!(contract in components)) {
        components[contract] = {
          title: contract,
          address: invoice.cups.full_address,
        };
      }

      if(amount_per_year[year][month][contract]) {
        // Add the energy and ammount
        energy_per_year[year][month][contract] += energy;
        amount_per_year[year][month][contract] += amount;
      } else {
        // Set the energy and ammount
        energy_per_year[year][month][contract] = energy;
        amount_per_year[year][month][contract] = amount;
      }
      // Override title by default by shorted mont and the year
      amount_per_year[year][month]["name"] =
        `${end_date.format("MMM")}'${end_date.format("YY")}`;
      energy_per_year[year][month]["name"] =
        `${end_date.format("MMM")}'${end_date.format("YY")}`;
    });

    let final_amount = [];
    let final_energy = [];
    for (let year = init_year; year <= current_year; year++) {
      for (let month = 0; month < number_of_months; month++) {
        //Select just non-empty elements
        amount_per_year[year][month].total = 0;
        if (Object.keys(amount_per_year[year][month]).length > 2) {
          // Calculate totals for each month
          _.forEach(Object.keys(amount_per_year[year][month]), (k) => {
            if (k !== "total" && k !== "name") {
              amount_per_year[year][month].total +=
                amount_per_year[year][month][k];
              energy_per_year[year][month].total +=
                energy_per_year[year][month][k];
            }
          });

          // Format decimals
          amount_per_year[year][month].total = Number(
            amount_per_year[year][month].total
          ).toFixed(2);
          energy_per_year[year][month].total = Number(
            energy_per_year[year][month].total
          ).toFixed(0);

          const the_amount = Object.assign({}, amount_per_year[year][month]);
          const the_energy = Object.assign({}, energy_per_year[year][month]);

          final_amount.push(the_amount);
          final_energy.push(the_energy);
        }
      }
    }

    return {
      energy: final_energy,
      amount: final_amount,
      components,
    };
  }, []);

  return (
    <>
      {loaded && invoices.length &&
        <DualLabelSwitch
          labelLeft={t('common:text.amount')}
          labelRight={t('common:text.energy')}
          onChange={status => setToggleLeft(status)}
          checked={toggleLeft}
          id="toggleElement"
        />
      }

      {loaded ?
        <BarChart
          data={toggleLeft ? (chartData?.energy ?? []) : (chartData?.amount ?? [])}
          components={chartData.components}
          unit={toggleLeft ? regional_settings.energy_unit : regional_settings.currency}
          animated={true}
          stacked={true}
        />
      :
        <ChartSkeleton />
      }
    </>

  )
}

export default InvoicesChart;
